import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { BeneficiaryPrimaryIdentifierAbbreviation, CustomExampleFilePerFlag } from '@wfp-root-app/appConfig';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { exhaustiveCheck } from '../../../../utils/utils';
import { UaopFlags } from '../../DryRunDialog';
import { useStepperContext } from '../StepperContext';
import {
    CheckBoxContainer,
    commonConfirmations,
    commonHouseholdLevelWinterCategoryConfirmations,
    commonIndividualLevelWinterCategoryConfirmations,
    CONFIRMATIONS,
    HouseholdExclusionModelImpl,
    HouseholdExclusionType,
    mapStrategyFlag,
} from '../utils/utils';

interface ConfirmationStepProps {
    selectedAssistanceCategory: string;
    selectedDeduplicationCategories: string[];
    selectedFlag: UaopFlags;
    hExclusion: HouseholdExclusionType;
    selectedConfirmations: string[];
    onConfirmationChange: (c: string[]) => void;
    allowedAdditionalDeduplicationCategories: string[];
    showHouseholdExclusionConfirmation: boolean;
    showDeduplicationCategoriesConfirmation: boolean;
    isEnterpriseCategory: boolean;
    customExampleFilePerFlag?: CustomExampleFilePerFlag;
}

const getConfirmationsArray = (
    selectedUaop: UaopFlags,
    selectedAssistanceCategory: string,
    identifierAbbreviation: string,
    supportEmail: string,
    isEnterpriseCategory: boolean
) => {
    let common = [];
    if (['CASH-WU', 'CASH-WE', 'CASH-WHA', 'CASH-RENT'].includes(selectedAssistanceCategory)) {
        common = [...commonHouseholdLevelWinterCategoryConfirmations];
    } else if (['CASH-WNFI', 'CASH-WC'].includes(selectedAssistanceCategory)) {
        common = [...commonIndividualLevelWinterCategoryConfirmations];
    } else {
        common = [...commonConfirmations(isEnterpriseCategory)];
    }

    const mappedConfirmations = common.map((confirmation) => ({
        ...confirmation,
        content: confirmation.content(identifierAbbreviation, supportEmail),
    }));

    switch (selectedUaop) {
        case UaopFlags.NoneHistorical:
            return [...mappedConfirmations];
        case UaopFlags.IncrementalShiftPeriod:
            return [
                ...mappedConfirmations,
                { content: CONFIRMATIONS.whenIncrementalShiftPeriod.content(identifierAbbreviation, supportEmail) },
                { content: CONFIRMATIONS.whenHistoricalSelected.content(identifierAbbreviation, supportEmail) },
            ];
        case UaopFlags.IncrementalDeltaAmountYesOption:
        case UaopFlags.IncrementalAnyAmount:
        case UaopFlags.IncrementalAnyAmountWithAllowedOverlap:
        case UaopFlags.IncrementalDeltaAmountNoOption:
        case UaopFlags.TotalAnyAmount:
        case UaopFlags.TotalDeltaAmount:
        case UaopFlags.TotalDeltaAmountWithShift:
        case UaopFlags.NoneIntendedAssistanceOverlap:
        case UaopFlags.IncrementalDeltaAmountV2:
        case UaopFlags.TotalDeltaAmountV2:
            return [
                ...mappedConfirmations,
                { content: CONFIRMATIONS.whenHistoricalSelected.content(identifierAbbreviation, supportEmail) },
            ];
        default:
            exhaustiveCheck(selectedUaop);
    }
};

const determineUrlWithExamplesForFlag = (
    selectedUaop: UaopFlags,
    customExampleFilePerFlag?: CustomExampleFilePerFlag
): string => {
    switch (selectedUaop) {
        case UaopFlags.IncrementalShiftPeriod:
            return (
                customExampleFilePerFlag?.[UaopFlags.IncrementalShiftPeriod] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_shift_period.html'
            );
        case UaopFlags.IncrementalDeltaAmountYesOption:
            return (
                customExampleFilePerFlag?.[UaopFlags.IncrementalDeltaAmountYesOption] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_delta_amount_yes_flag.html'
            );
        case UaopFlags.IncrementalDeltaAmountNoOption:
            return (
                customExampleFilePerFlag?.[UaopFlags.IncrementalDeltaAmountNoOption] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_delta_amount_no_flag.html'
            );
        case UaopFlags.IncrementalAnyAmount:
            return (
                customExampleFilePerFlag?.[UaopFlags.IncrementalAnyAmount] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_any_amount.html'
            );
        case UaopFlags.TotalAnyAmount:
            return (
                customExampleFilePerFlag?.[UaopFlags.TotalAnyAmount] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_total_any_amount.html'
            );
        case UaopFlags.IncrementalAnyAmountWithAllowedOverlap:
            return (
                customExampleFilePerFlag?.[UaopFlags.IncrementalAnyAmountWithAllowedOverlap] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_intended_assistance_overlap.html'
            );
        case UaopFlags.TotalDeltaAmount:
            return (
                customExampleFilePerFlag?.[UaopFlags.TotalDeltaAmount] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_total_delta_amount.html'
            );
        case UaopFlags.TotalDeltaAmountWithShift:
            return (
                customExampleFilePerFlag?.[UaopFlags.TotalDeltaAmountWithShift] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_total_delta_amount_shift_period.html'
            );
        case UaopFlags.IncrementalDeltaAmountV2:
            return (
                customExampleFilePerFlag?.[UaopFlags.IncrementalDeltaAmountV2] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_incremental_delta_amount_v2.html'
            );
        case UaopFlags.TotalDeltaAmountV2:
            return (
                customExampleFilePerFlag?.[UaopFlags.TotalDeltaAmountV2] ||
                'https://wfp-public.s3.eu-central-1.amazonaws.com/UAOP_total_delta_amount_v2.html'
            );
        case UaopFlags.NoneIntendedAssistanceOverlap:
        case UaopFlags.NoneHistorical:
            return '';
        default:
            return '';
    }
};

const ConfirmationStep: FC<ConfirmationStepProps> = (props) => {
    const [confirmations, setConfirmations] = useState([]);
    const { enableNextStep, disableNextStep } = useStepperContext();
    const beneficiaryPrimaryIdentifierType = useSelector(
        (state: State) => state.appConfig.beneficiaryPrimaryIdentifierType
    );
    const supportEmail = useSelector((state: State) => state.appConfig.supportEmail);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const identifierAbbreviation = BeneficiaryPrimaryIdentifierAbbreviation[beneficiaryPrimaryIdentifierType];
    useEffect(() => {
        props.onConfirmationChange([]);
        setConfirmations(
            getConfirmationsArray(
                props.selectedFlag,
                props.selectedAssistanceCategory,
                identifierAbbreviation,
                supportEmail,
                props.isEnterpriseCategory
            )
        );
    }, [
        props.selectedFlag,
        props.selectedAssistanceCategory,
        identifierAbbreviation,
        supportEmail,
        props.isEnterpriseCategory,
    ]);

    useEffect(() => {
        isConfirmed ? enableNextStep() : disableNextStep();
    }, [isConfirmed]);

    const renderScenariosLink = () => {
        const link = determineUrlWithExamplesForFlag(props.selectedFlag);
        if (!link) return;

        return (
            <a
                href={link}
                rel="noreferrer"
                style={{
                    fontWeight: 'bold',
                    color: 'red',
                    background: 'yellow',
                    border: '2px',
                    borderStyle: 'solid',
                    borderColor: 'black',
                }}
                target="_blank"
            >
                Click here to see example scenarios based on the UAOP option you selected
            </a>
        );
    };

    return (
        <div className={'wfp-confirmation-step'}>
            <div className={'mb-3 mt-3'}>
                <div>
                    <span className={'font-weight-bold'}>Assistance Category: </span>
                    <span>{props.selectedAssistanceCategory}</span>
                </div>
                {props.showDeduplicationCategoriesConfirmation && (
                    <div>
                        <span className={'font-weight-bold'}>Inter-Category: </span>
                        <span>
                            {props.selectedDeduplicationCategories.length > 0
                                ? props.selectedDeduplicationCategories.join(', ')
                                : 'none'}
                        </span>
                    </div>
                )}
                <div>
                    <span className={'font-weight-bold'}>Strategy: </span>
                    <span>{mapStrategyFlag(props.selectedFlag)}</span>
                </div>
                {props.showHouseholdExclusionConfirmation && (
                    <div>
                        <span className={'font-weight-bold'}>Previous Household Exclusion: </span>
                        <span>{HouseholdExclusionModelImpl[props.hExclusion].visibleName}</span>
                    </div>
                )}
                <div>{renderScenariosLink()}</div>
            </div>

            <div>
                <h6>Please confirm that:</h6>

                <div>
                    <CheckBoxContainer marginTop="0px">
                        {confirmations?.map((confirmation, id) => (
                            <div key={id}>
                                <label
                                    style={{
                                        fontStyle: 'italic',
                                        fontSize: '16px',
                                        display: 'flex',
                                        gap: '5px',
                                        alignItems: 'center',
                                    }}
                                >
                                    {id === 0 ? (
                                        <input
                                            checked={isConfirmed}
                                            onChange={() => {
                                                setIsConfirmed(!isConfirmed);
                                            }}
                                            style={{ marginTop: '5px', width: '32px' }}
                                            type="checkbox"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <div>{confirmation.content}</div>
                                </label>
                            </div>
                        ))}
                    </CheckBoxContainer>
                </div>
            </div>
        </div>
    );
};

ConfirmationStep.displayName = 'ConfirmationStep';

export { ConfirmationStep };
