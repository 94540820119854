import React from 'react';

import { UaopFlags } from '../../DryRunDialog';
import { Paragraph } from './utils';

export enum MetaCategories {
    CWG = 'CWG',
    NFI = 'NFI',
    FSL = 'FSL',
}

export type MetaCategoriesConfiguration = {
    default?: React.ReactNode;
    customPerMetaCategory?: {
        [key in MetaCategories]?: React.ReactNode;
    };
};

export type UaopFlagToMetaCategoryMapping = {
    [key in UaopFlags]?: MetaCategoriesConfiguration;
};

export const textMapping: UaopFlagToMetaCategoryMapping = {
    [UaopFlags.IncrementalAnyAmount]: {
        default: (
            <Paragraph>
                Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the indicated
                assistance period in my file, regardless of the amount and overlap period of the existing assistance, no
                additional assistance is shown as being made available by me. For example, if existing assistance to a
                household is $3 from Jan-Jun, and my assistance is $6 from Apr-Sept, completely skip my assistance.
            </Paragraph>
        ),
        customPerMetaCategory: {
            [MetaCategories.NFI]: (
                <Paragraph>
                    Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                    indicated assistance period in my file, regardless of the amount, no additional assistance is shown
                    as being made available by me. For example, if existing assistance to a household is $3, and my
                    assistance is $6 for an overlapping period, completely skip my assistance.
                </Paragraph>
            ),
        },
    },
    [UaopFlags.IncrementalAnyAmountWithAllowedOverlap]: {
        default: (
            <Paragraph>
                Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the indicated
                assistance period in my file, regardless of the amount, the assistance may overlap by a maximum of 1
                month. If there is more than a 1 month overlap period of existing assistance, no additional assistance
                is shown as being made available by me. For example, if existing assistance to a household is $3 from
                Jan-Mar, and my assistance is $6 from Mar-May (1-month overlap), allow my assistance. If existing
                assistance to a household is $3 from Jan-Mar, and my assistance is $6 from Feb-Apr (2-month overlap),
                completely skip my assistance.
            </Paragraph>
        ),
    },
};

export const getTextByMetaCategoryAndFlag = (metaCategory: MetaCategories, uaopFlag: UaopFlags) => {
    const text = textMapping[uaopFlag];
    if (!text || (!text.default && !text.customPerMetaCategory)) {
        throw new Error(`Missing text mapping for uaop flag: ${uaopFlag}`);
    }

    const customText = text?.customPerMetaCategory?.[metaCategory];

    return customText || text.default;
};
